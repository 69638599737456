import React, { useState, useEffect } from "react";
import AuctionSlotsData from "../../../utils/json/auctionSlotsData.json";
import "../homeCSS/Auction-Page-Style/auctionPost.css";

const AuctionSlotData = ({ slot }) => {
  const [comments, setComments] = useState(slot?.participants || []);
  const [newPrize, setNewPrize] = useState("");

  const handleSetPrize = () => {
    // Assume that the user is the current participant
    if (newPrize >= slot.lowestPrice && newPrize <= slot.highestPrice) {
      const participantId = "current_user_id";
      const newComment = {
        _id: `comment${comments.length + 1}_id`, // Generate a unique comment ID
        name: "newParticipant",
        prize: parseInt(newPrize),
      };
      const updatedComments = [...comments, newComment];
      setComments(updatedComments);
      // onUpdatePrize(slot.id, newComment.participantId, newComment.prize);
      setNewPrize("");
    } else {
      alert("Please set prize under lowest and highest.");
    }
  };

  return (
    <>
      <h5>Bike Info</h5>
      <div className="bike-images">
        {slot.bikeImages.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Bike ${index + 1}`}
            className="bike-image"
          />
        ))}
      </div>
      <div className="bikeInfo">
        <p>{slot.info}</p>
        <p>Lowest Price: ${slot.lowestPrice}</p>
        <p>Highest Price: ${slot.highestPrice}</p>
      </div>

      <h3>Participants</h3>
      <ul>
        {comments?.map((participant, index) => (
          <li key={index}>
            <div className="participantTag">
              <div className="pName">{participant.name}: </div>
              <p> {participant.prize}</p>
            </div>
          </li>
        ))}
      </ul>
      <div className="setPrize">
        <input
          type="text"
          value={newPrize}
          onChange={(e) => setNewPrize(e.target.value)}
        />
        <button onClick={handleSetPrize}>Set Prize</button>
      </div>
    </>
  );
};

const CreatedSLotByOtherUsers = () => {
  const [slots, setSlots] = useState(
    AuctionSlotsData?.RunningByOtherSlotsData || []
  );
  return (
    <div className="slotUpperCover">
      <h4>Created Slot By Other Users</h4>
      <div className="slotCover">
        {slots.map((slot, index) => (
          <div className="auctionSlotPost" key={index}>
            <h2>Slot {index + 1}</h2>
            <AuctionSlotData slot={slot} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CreatedSLotByOtherUsers;
