import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Css/defaultCSSForAuthFLow.css";
import "./Css/opt.css";
import { APP_ROUTES } from "../../utils/AppConstants";
import { useDispatch, useSelector } from "react-redux";
import { resetatStatus } from "../../api/reducerSlices/authSlice";
import { verifyOTP } from "../../api/actions/authActions";

const OTPScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    status,
    error,
    message,
    authenticated,
    authenticatedStatus,
    yourotp,
    user_idfr,
  } = useSelector((state) => state.auth);
  const [optValues, setOptValues] = useState({
    opt1: "",
    opt2: "",
    opt3: "",
    opt4: "",
    opt5: "",
  });

  const [OTP, setOTP] = useState("");

  useEffect(() => {
    if (authenticatedStatus === "registration") {
      if (status === "succeeded" && authenticated === true) {
        alert(message);
        navigate(APP_ROUTES.SIGN_IN_ROUTE);
        dispatch(resetatStatus());
      }
      if (status === "failed") {
        alert(error || "Please try again!");
      }
    } else if (
      authenticatedStatus !== "registration" &&
      status === "succeeded"
    ) {
      navigate(APP_ROUTES.RESET_PASSWORD_ROUTE);
      dispatch(resetatStatus());
    } else {
      message && alert(message);
    }
  }, [status, authenticated]);

  const handleInputChange = (e, fieldName) => {
    const { value } = e.target;

    setOptValues((prevValues) => ({
      ...prevValues,
      [fieldName]: value,
    }));

    if (value.length === 1) {
      const nextFieldName = getNextFieldName(fieldName);
      const nextInput = document.getElementsByName(nextFieldName)[0];
      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  // const resendOtp = () => {};

  const handleSubmit = async (e) => {
    e.preventDefault();
    let otp = "";
    if (Object.values(optValues).every((value) => value.length === 1)) {
      Object.values(optValues).forEach((element) => {
        otp += element;
      });

      setOTP(otp);
      console.log(OTP);
      const dataToSend = {
        user_id: user_idfr,
        otp: otp,
      };
      dispatch(verifyOTP(dataToSend));

      // if (authenticatedStatus === "registration") {
      //   if (status === "succeeded" && authenticated === true) {
      //     alert(message);
      //     navigate(APP_ROUTES.SIGN_IN_ROUTE);
      //     dispatch(resetatStatus());
      //   }
      //   if (status === "failed") {
      //     alert(error || "Please try again!");
      //   }
      // } else {
      //   navigate(APP_ROUTES.RESET_PASSWORD_ROUTE);
      //   dispatch(resetatStatus());
      // }
    } else {
      // Display an error message or take appropriate action
      alert("Please fill in all fields.");
    }
  };

  const getNextFieldName = (currentFieldName) => {
    const fieldOrder = ["opt1", "opt2", "opt3", "opt4", "opt5"];
    const currentIndex = fieldOrder.indexOf(currentFieldName);
    return currentIndex < fieldOrder.length - 1
      ? fieldOrder[currentIndex + 1]
      : null;
  };

  return (
    <div className="authPage">
      <div className="ContentBox">
        <div className="coverBox">
          <span>Ride Rental Hub</span>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci
            dolorem voluptatibus minus totam sit sequi!
          </p>
          <div className="coverBoxPic">{/* <img src={} alt="" /> */}</div>
        </div>
        <div className="formBox bx">
          <form onSubmit={handleSubmit}>
            <span>OTP</span>
            <div className="inputBox">
              {["opt1", "opt2", "opt3", "opt4", "opt5"].map(
                (fieldName, index) => (
                  <input
                    key={fieldName}
                    type="text"
                    name={fieldName}
                    maxLength="1"
                    value={optValues[fieldName]}
                    onChange={(e) => handleInputChange(e, fieldName)}
                    autoFocus={index === 0}
                  />
                )
              )}
            </div>
            {yourotp && <div> {yourotp}</div>}
            {/* <button
              className="btn-nbg"
              onClick={(e) => {
                e.preventDefault();
                resendOtp();
              }}
            >
              Resend code
            </button> */}
            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default OTPScreen;
