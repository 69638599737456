// import React, { useState, useEffect } from "react";
// // import { Link, useNavigate } from "react-router-dom";
// import { Link, useNavigate } from "react-router-dom";
// import ShowPasswordIcon from "../../assets/svgs/ShowPasswordIcon";
// import HidePasswordIcon from "../../assets/svgs/HidePasswordIcon";
// import "./Css/defaultCSSForAuthFLow.css";
// // import "./Css/signup.css";
// import { APP_ROUTES } from "../../utils/AppConstants";
// import axios from "axios";
// import { useDispatch, useSelector } from "react-redux";
// import { registerUser } from "../../api/actions/authActions";
// import { resetError } from "../../api/reducerSlices/authSlice";

// const SignUp = () => {
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const [getError, setError] = useState("");
//   const [showPassword, setShowPassword] = useState("");
//   const [showConfirmPassword, setShowConfirmPassword] = useState("");

//   const [formDataForSignUp, setFormDataForSignUp] = useState({
//     name: "",
//     email: "",
//     password: "",
//     // confirmPassword: "",
//   });
//   const { status, error, message, authenticated } = useSelector(
//     (state) => state.auth
//   );

//   useEffect(() => {
//     if (status === "succeeded" && authenticated === true) {
//       alert("You are registered now!");
//       navigate(APP_ROUTES.VERIFY_OTP_ROUTE, {
//         state: { navigateToRoute: APP_ROUTES.SIGN_IN_ROUTE },
//       });
//       dispatch(resetError());
//     }

//     if (status === "failed" && error !== "") {
//       alert(error);
//     }
//   }, [status, authenticated]);

//   const handleChange = (e) => {
//     setFormDataForSignUp({
//       ...formDataForSignUp,
//       [e.target.id]: e.target.value,
//     });
//     setError("");
//   };

//   const togglePasswordVisibility = (field) => {
//     if (field === "password") {
//       setShowPassword(!showPassword);
//     } else if (field === "confirmPassword") {
//       setShowConfirmPassword(!showConfirmPassword);
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     if (
//       !formDataForSignUp.name.trim() ||
//       !formDataForSignUp.email.trim() ||
//       !formDataForSignUp.password.trim() ||
//       !formDataForSignUp.confirmPassword.trim()
//     ) {
//       setError("Please fill in all fields");
//       return;
//     }

//     if (formDataForSignUp.password !== formDataForSignUp.confirmPassword) {
//       setError("Password and Confirm Password must match");
//       return;
//     }

//     const dataToSendForSignup = {
//       name: formDataForSignUp.name.trim(),
//       email: formDataForSignUp.email.trim(),
//       password: formDataForSignUp.password.trim(),
//       // confirmPassword: formDataForSignUp.confirmPassword.trim(),
//     };
//     // dispatch(registerUser(dataToSendForSignup));
//     // const result = await
//     dispatch(registerUser(dataToSendForSignup));
//   };

//   return (
//     <div className="authPage">
//       <div className="ContentBox">
//         <div className="coverBox">
//           <span>Ride Rental Hub</span>
//           <p>
//             Sign up for Ride Rental Hub in seconds. Just enter your basic
//             details and start exploring ride rentals hassle-free!
//           </p>
//           <div className="coverBoxPic">{/* <img src={} alt="" /> */}</div>
//         </div>
//         <div className="formBox">
//           {/* <div className="box boxSU boxSUD" style={boxSUDClass}> */}
//           <form onSubmit={handleSubmit}>
//             <span>
//               <div className="hide">Welcome to</div>
//               <span className="space">SIGN UP</span>
//             </span>
//             <p>Enter Your Credentials And Get Ready To Explore!</p>
//             <div className="inputBox">
//               <label htmlFor="name">Name:</label>
//               <input
//                 type="text"
//                 id="name"
//                 name="name"
//                 placeholder="name"
//                 value={formDataForSignUp.name}
//                 onChange={handleChange}
//                 autoFocus
//               />
//             </div>
//             <div className="inputBox">
//               <label htmlFor="email">Email Address:</label>
//               <input
//                 type="email"
//                 name="email"
//                 id="email"
//                 placeholder="example@gmail.com"
//                 value={formDataForSignUp.email}
//                 onChange={handleChange}
//               />
//             </div>
//             <div className="inputBox pos-rel">
//               <label htmlFor="password">Password:</label>
//               <input
//                 type={showPassword ? "text" : "password"}
//                 id="password"
//                 name="password"
//                 placeholder="**************"
//                 value={formDataForSignUp.password}
//                 onChange={handleChange}
//               />
//               <button
//                 type="button"
//                 className="password-toggle btn"
//                 onClick={() => togglePasswordVisibility("password")}
//               >
//                 {showPassword ? <ShowPasswordIcon /> : <HidePasswordIcon />}
//               </button>
//             </div>
//             <div className="inputBox pos-rel">
//               <label htmlFor="confirmPassword">Confirm Password:</label>
//               <input
//                 type={showConfirmPassword ? "text" : "password"}
//                 id="confirmPassword"
//                 name="confirmPassword"
//                 placeholder="**************"
//                 value={formDataForSignUp.confirmPassword}
//                 onChange={handleChange}
//               />
//               <button
//                 type="button"
//                 className="password-toggle btn"
//                 onClick={() => togglePasswordVisibility("confirmPassword")}
//               >
//                 {showConfirmPassword ? (
//                   <ShowPasswordIcon />
//                 ) : (
//                   <HidePasswordIcon />
//                 )}
//               </button>
//             </div>
//             {getError && <span className="error">{getError}</span>}
//             <button type="submit">SIGN UP</button>
//           </form>
//           <p>
//             Already a Member?
//             <Link to={APP_ROUTES.SIGN_IN_ROUTE} className="login">
//               Login
//             </Link>
//           </p>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default SignUp;

import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import ShowPasswordIcon from "../../assets/svgs/ShowPasswordIcon";
import HidePasswordIcon from "../../assets/svgs/HidePasswordIcon";
import "./Css/defaultCSSForAuthFLow.css";
import { APP_ROUTES } from "../../utils/AppConstants";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { registerUser } from "../../api/actions/authActions";
import { resetError } from "../../api/reducerSlices/authSlice";

const SignUp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [getError, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [formDataForSignUp, setFormDataForSignUp] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const { status, error, authenticated } = useSelector((state) => state.auth);

  useEffect(() => {
    if (status === "succeeded" && authenticated === true) {
      alert("You are registered now!");
      // navigate(APP_ROUTES.VERIFY_OTP_ROUTE, {
      //   state: { navigateToRoute: APP_ROUTES.SIGN_IN_ROUTE },
      // });
      navigate(APP_ROUTES.SIGN_IN_ROUTE);
      dispatch(resetError());
    }

    if (status === "failed" && error !== "") {
      alert(error);
    }
  }, [status, authenticated]);

  const handleChange = (e) => {
    setFormDataForSignUp({
      ...formDataForSignUp,
      [e.target.id]: e.target.value,
    });
    setError("");
  };

  const togglePasswordVisibility = (field) => {
    if (field === "password") {
      setShowPassword(!showPassword);
    } else if (field === "confirmPassword") {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };

  const validateUsername = (name) => {
    const usernameRegex = /^[A-Za-z]{3,}[\w]*$/;
    return usernameRegex.test(name);
  };

  const validateEmail = (email) => {
    const emailRegex =
      /^[A-Za-z][A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    return emailRegex.test(email);
  };

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/;
    return passwordRegex.test(password);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { name, email, password, confirmPassword } = formDataForSignUp;

    if (
      !name.trim() ||
      !email.trim() ||
      !password.trim() ||
      !confirmPassword.trim()
    ) {
      setError("Please fill in all fields");
      return;
    }

    if (!validateUsername(name)) {
      setError("Username must start with at least three alphabetic characters");
      return;
    }

    if (!validateEmail(email)) {
      setError("Please enter a valid email address");
      return;
    }

    if (!validatePassword(password)) {
      setError(
        "Password must be at least 8 characters long, contain a special character, and a capital letter"
      );
      return;
    }

    if (password !== confirmPassword) {
      setError("Password and Confirm Password must match");
      return;
    }

    const dataToSendForSignup = {
      name: name.trim(),
      email: email.trim(),
      password: password.trim(),
    };

    dispatch(registerUser(dataToSendForSignup));
  };

  return (
    <div className="authPage">
      <div className="ContentBox">
        <div className="coverBox">
          <span>Ride Rental Hub</span>
          <p>
            Sign up for Ride Rental Hub in seconds. Just enter your basic
            details and start exploring ride rentals hassle-free!
          </p>
        </div>
        <div className="formBox">
          <form onSubmit={handleSubmit}>
            <span>
              <div className="hide">Welcome to</div>
              <span className="space">SIGN UP</span>
            </span>
            <p>Enter Your Credentials And Get Ready To Explore!</p>
            <div className="inputBox">
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="name"
                value={formDataForSignUp.name}
                onChange={handleChange}
                autoFocus
              />
            </div>
            <div className="inputBox">
              <label htmlFor="email">Email Address:</label>
              <input
                type="email"
                name="email"
                id="email"
                placeholder="example@gmail.com"
                value={formDataForSignUp.email}
                onChange={handleChange}
              />
            </div>
            <div className="inputBox pos-rel">
              <label htmlFor="password">Password:</label>
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                name="password"
                placeholder="**************"
                value={formDataForSignUp.password}
                onChange={handleChange}
              />
              <button
                type="button"
                className="password-toggle btn"
                onClick={() => togglePasswordVisibility("password")}
              >
                {showPassword ? <ShowPasswordIcon /> : <HidePasswordIcon />}
              </button>
            </div>
            <div className="inputBox pos-rel">
              <label htmlFor="confirmPassword">Confirm Password:</label>
              <input
                type={showConfirmPassword ? "text" : "password"}
                id="confirmPassword"
                name="confirmPassword"
                placeholder="**************"
                value={formDataForSignUp.confirmPassword}
                onChange={handleChange}
              />
              <button
                type="button"
                className="password-toggle btn"
                onClick={() => togglePasswordVisibility("confirmPassword")}
              >
                {showConfirmPassword ? (
                  <ShowPasswordIcon />
                ) : (
                  <HidePasswordIcon />
                )}
              </button>
            </div>
            {getError && <span className="error">{getError}</span>}
            <button type="submit">SIGN UP</button>
          </form>
          <p>
            Already a Member?
            <Link to={APP_ROUTES.SIGN_IN_ROUTE} className="login">
              Login
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
