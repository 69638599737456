import React, { useState, useEffect } from "react";
import AuctionSlotsData from "../../../utils/json/auctionSlotsData.json";
import "../homeCSS/Auction-Page-Style/auctionPost.css";

const AuctionSlotData = ({ slot }) => {
  // const [comments, setComments] = useState(slot?.participants);
  // const [newPrize, setNewPrize] = useState("");
  return (
    <>
      <h5>Bike Info</h5>
      <div className="bike-images">
        {slot.bikeImages.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Bike ${index + 1}`}
            className="bike-image"
          />
        ))}
      </div>
      <div className="bikeInfo">
        <p>{slot.info}</p>
        <p>Lowest Price: ${slot.lowestPrice}</p>
        <p>Highest Price: ${slot.highestPrice}</p>
      </div>

      <h3>Participants</h3>
      <ul>
        {slot.participants.map((participant, index) => (
          <li key={index}>
            <div className="participantTag">
              <div className="pName">{participant.name}: </div>
              <p> {participant.prize}</p>
              <button>Accept</button>
            </div>
          </li>
        ))}
      </ul>
      <div>
        {/* <input
          type="text"
          value={newPrize}
          onChange={(e) => setNewPrize(e.target.value)}
        />
        <button onClick={handleSetPrize}>Set Prize</button> */}
      </div>
    </>
  );
};

const OwnedCreatedSlot = () => {
  const [slots, setSlots] = useState(AuctionSlotsData?.ownedSlotsData || []);

  return (
    <div className="ownedCreated">
      <h4>Owned Created Slots</h4>
      <div className="slotCover">
        {slots.map((slot, index) => (
          <div className="auctionSlotPost" key={index}>
            <h2>Slot {index + 1}</h2>
            <AuctionSlotData slot={slot} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default OwnedCreatedSlot;
